import React from "react";
import { navigate } from "gatsby";

import { formatPrice } from "utils/formatting";
import { useAddNotification } from "state/notifications";
import useProductForm from "hooks/useProductForm";

import Button from "components/Button";
import Form, { useValue } from "components/Form";
import FormField from "components/FormField";

const ProductForm = ({ product, ...props }) => {
  const addNotification = useAddNotification();
  const {
    handleOptionChange,
    handleSubmit: shopifySubmit,
    options,
    status,
    variant,
    defaultValues,
  } = useProductForm(product);

  const handleSubmit = React.useCallback(
    async data => {
      await shopifySubmit(data);

      addNotification({
        status: "success",
        message: `Added to cart: ${data.quantity || 1} × (${
          product.productType
        }) ${product.title}`,
        actions: [["View cart", () => navigate("/cart")]],
      });
    },
    [addNotification, product, shopifySubmit]
  );

  const getHandleOptionChange = React.useCallback(
    name => {
      return value => {
        handleOptionChange({ name, value });
      };
    },
    [handleOptionChange]
  );

  return (
    <Form
      defaultValues={{ ...defaultValues, quantity: 1 }}
      onSubmit={handleSubmit}
      {...props}
    >
      {options.map(({ name, values }) => (
        <FormField
          key={name}
          type={name === "Type" ? "tiles" : "select"}
          name={name}
          label={name}
          options={values}
          onChange={getHandleOptionChange(name)}
          required={name}
        />
      ))}
      <FormField
        type="counter"
        label="Quantity"
        name="quantity"
        min={1}
        required
      />
      <SubmitButton status={status} variant={variant} />
    </Form>
  );
};

function SubmitButton({ status, variant }) {
  const [quantity] = useValue("quantity");
  return (
    <Button
      key="submit"
      type="submit"
      disabled={!variant.availableForSale}
      variant="submit"
    >
      {getCTA({ ...variant, ...status, quantity })}
    </Button>
  );
}

function getCTA({
  availableForSale,
  isAdded,
  isAdding,
  isFetchingRemote,
  price,
  priceV2,
  quantity,
}) {
  if (isFetchingRemote) {
    return "Checking availability…";
  } else if (isAdding) {
    return "Adding…";
  } else if (isAdded) {
    return "Added!";
  } else if (!availableForSale) {
    return "Currently out of stock";
  } else {
    return `Add to cart\u2003|\u2003${formatPrice(priceV2 || price, quantity)}`;
  }
}

export default ProductForm;

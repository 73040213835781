import React from "react";
import useSWR from "swr";
import createStore from "zustand";

import { get } from "../utils/helpers";
import { decodeStorefrontId, isNotDefaultOption } from "../utils/shopify";
import { useAddItemToCart } from "../state/shopify";
import { queryStorefront } from "../services/shopify";

const queryProductByHandle = `
  query getProductByHandle($handle: String!) {
    product: productByHandle(handle: $handle) {
      variants(first: 250) {
        edges {
          node {
            id
            availableForSale
            currentlyNotInStock
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`;

// variants: [],
// options: [],
// defaultValues: {},
// selectedVariant: undefined,

const useProductStore = createStore((setState, getState) => ({
  products: {},
  status: {
    isAdding: false,
    isAdded: false,
    isFetchingRemote: true,
    isInitialized: false,
  },
  setInitialData: ({ handle, data }) => {
    if (getState().products[handle]) {
      return;
    }

    setState(prev => ({
      ...prev,
      status: {
        ...prev.status,
        isInitialized: true,
      },
      products: {
        ...prev.products,
        [handle]: data,
      },
    }));
  },
  setRemoteData: ({ handle, data }) => {
    const variants = get(data, "data.product.variants.edges", []).map(
      ({ node }) => ({
        ...node,
        id: decodeStorefrontId(node.id),
        encodedId: node.id,
      })
    );

    setState(prev => {
      const next = {
        ...prev.products[handle],
        variants: prev.products[handle].variants.map(variant => {
          const newVariantData = variants.find(el => el.id === variant.id);
          return newVariantData ? { ...variant, ...newVariantData } : variant;
        }),
      };

      return {
        ...prev,
        products: {
          ...prev.products,
          [handle]: next,
        },
        status: {
          ...prev.status,
          isFetchingRemote: false,
        },
      };
    });
  },
  setVariantBySelectedOption: ({ handle, option }) => {
    const { variants, selectedVariant } = getState().products[handle];
    const current = variants.find(el => el.id === selectedVariant);
    const selectedOptions = [option];
    if (current && current.selectedOptions) {
      current.selectedOptions.forEach(o => {
        if (o.name !== option.name) {
          selectedOptions.push(o);
        }
      });
    }

    const variant = variants.find(variant => {
      return selectedOptions.every(({ name, value }) => {
        return variant.selectedOptions.some(o => {
          return o.name === name && o.value === value;
        });
      });
    });

    if (variant) {
      setState(prev => ({
        ...prev,
        products: {
          ...prev.products,
          [handle]: {
            ...prev.products[handle],
            selectedVariant: variant.id,
          },
        },
      }));
    }
  },
  setStatus: status => {
    setState(prev => ({ ...prev, status: { ...prev.status, ...status } }));
  },
}));

function useProductData({ handle }) {
  const productData = useProductStore(
    React.useCallback(
      state => {
        return state.products[handle];
      },
      [handle]
    )
  );

  if (!productData) {
    return;
  }

  const variant = productData.variants.find(el => {
    return el.id === productData.selectedVariant;
  });

  return {
    defaultValues: productData.defaultValues,
    options: productData.options,
    variant: variant || {},
  };
}

function useProductFormStatus() {
  return useProductStore(
    React.useCallback(state => {
      return { status: state.status, setStatus: state.setStatus };
    }, [])
  );
}

function useProductFormStore() {
  return useProductStore(
    React.useCallback(state => {
      return {
        setInitialData: state.setInitialData,
        setRemoteData: state.setRemoteData,
        setVariantBySelectedOption: state.setVariantBySelectedOption,
      };
    }, [])
  );
}

/**
 * useProductForm
 *
 * TODO
 * get better initial data from sanity (via serverless)
 */
export default function useProductForm(product) {
  const store = useProductFormStore();
  const { status, setStatus } = useProductFormStatus();
  const productData = useProductData(product);
  const addItemToCart = useAddItemToCart();

  const initialState = React.useMemo(() => {
    return getInitialState(product);
  }, [product]);

  React.useEffect(() => {
    if (initialState) {
      store.setInitialData({
        handle: product.handle,
        data: initialState,
      });
    }
  }, [initialState, product.handle, store]);

  useSWR(product.handle, fetchByHandle, {
    onSuccess: (data, key) => {
      store.setRemoteData({ handle: key, data });
    },
    refreshInterval: 5 * 60 * 1000,
  });

  const handleSubmit = React.useCallback(
    async data => {
      const variantId = productData.variant.encodedId;
      setStatus({ isAdding: true });
      await addItemToCart({
        variantId,
        quantity: data && data.quantity ? data.quantity : 1,
      });
      setStatus({ isAdding: false, isAdded: true });
      setTimeout(() => {
        setStatus({ isAdded: false });
      }, 1500);
    },
    [addItemToCart, setStatus, productData]
  );

  const handleOptionChange = React.useCallback(
    option => {
      store.setVariantBySelectedOption({ handle: product.handle, option });
    },
    [product.handle, store]
  );

  return productData
    ? {
        handleOptionChange,
        handleSubmit,
        status,
        options: [],
        ...productData,
      }
    : {
        handleOptionChange,
        handleSubmit,
        status,
        options: initialState.options || [],
        defaultValues: initialState.defaultValues,
        variant:
          initialState.variants.find(
            el => el.id === initialState.selectedVariant
          ) || {},
      };
}

async function fetchByHandle(...args) {
  const handle = args[0];
  return queryStorefront({
    query: queryProductByHandle,
    variables: { handle },
  });
}

function getInitialState(product) {
  try {
    const data = JSON.parse(product.stringifiedProductData);
    const variant = data.variants[0];
    const selectedVariant = variant.id;
    const options = data.options.filter(isNotDefaultOption);
    const defaultValues = options.reduce((memo, option, i) => {
      const value = variant[`option${i + 1}`];
      return value ? { ...memo, [option.name]: value } : memo;
    }, {});

    return {
      options,
      variants: data.variants,
      defaultValues,
      selectedVariant,
    };
  } catch (error) {
    return {
      options: [],
      defaultValues: {},
      variants: [],
    };
  }
}

import React from "react";

import BlockContent from "components/BlockContent";
import Box from "components/Box";
import Button from "components/Button";
import Form from "components/ProductForm";
import Heading from "components/Heading";
import Image from "components/Image";
import LinkedData from "components/LinkedData";
import Section from "components/SectionWrapper";
import Text from "components/Text";

const style = {
  wrapper: {
    width: "100%",
    maxWidth: "xxl",
    mx: "auto",
    bg: "white",
    py: 5,
    borderRadius: "lg",
  },
  container: {
    display: "grid",
    gridTemplateColumns: ["1fr", "repeat(2, minmax(0px, 1fr))"],
    alignItems: "start",
    gap: 4,
    maxWidth: "xl",
    mx: "auto",
    px: 3,
    py: 5,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    width: "100%",
    maxWidth: "sm",
    "& > *": {
      mb: 3,
    },
  },
  cta: {
    p: 4,
    mx: -4,
    bg: "turquoise.50",
    alignSelf: "stretch",
    textAlign: "center",
    borderRadius: "sm",
    color: "turquoise.900",
    "& > *": {
      mb: 3,
    },
    "& a": {
      display: "block",
    },
  },
  image: {
    borderRadius: "sm",
    boxShadow: "lg",
    maxWidth: ["sm", "sm", "md"],
  },
  column: {
    px: [0, 0, 4],
    "h1, p": {
      maxWidth: "sm",
    },
  },
};

const ProductPage = ({ content }) => {
  const { main, shopify } = content;

  return (
    <Section as="article">
      <Box sx={style.container}>
        {shopify && <LinkedData type="product" {...content} />}

        {/* product image */}
        <Box sx={style.column}>
          {main.mainImage && (
            <Image
              width={400}
              layout="fullWidth"
              sx={style.image}
              {...main.mainImage}
            />
          )}
        </Box>

        {/* product meta and form */}
        <Box sx={style.column}>
          <header>
            <Text as="span" variant="eyebrow">
              {main.productType}
            </Text>
            <Heading as="h1">{main.title}</Heading>
          </header>
          {main.productDescription && (
            <BlockContent blocks={main.productDescription} />
          )}
          {shopify ? (
            <Form product={shopify} sx={style.form} />
          ) : (
            <Box sx={style.cta}>
              <Text as="p">
                This item is availble from <strong>{main.vendorName}</strong>.
              </Text>
              <Button to={main.url}>
                <span>Purchase from</span> <strong>{main.vendorName}</strong>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Section>
  );
};

export default ProductPage;

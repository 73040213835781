import React from "react";
import { graphql } from "gatsby";

import Meta from "components/Meta";
import Product from "components/ProductPage";

const PageTemplate = ({ data, previewData }) => {
  const content = previewData
    ? previewData.content
    : data.sanityProduct._rawContent;

  return (
    <React.Fragment>
      <Meta
        {...content.meta}
        title={
          content.meta && content.meta.title
            ? content.meta.title
            : content.main.title
        }
      />
      <Product content={content} />
    </React.Fragment>
  );
};

export default PageTemplate;

export const query = graphql`
  query sanityProduct($id: String!) {
    sanityProduct(id: { eq: $id }) {
      _rawContent(resolveReferences: { maxDepth: 12 })
    }
  }
`;

const SHOPIFY_STORE = process.env.GATSBY_SHOPIFY_STORE;
const STOREFRONT_ACCESS_TOKEN = process.env.GATSBY_SHOPIFY_TOKEN;

export async function queryStorefront(data) {
  try {
    const body = JSON.stringify(data);
    const response = await fetch(
      `https://${SHOPIFY_STORE}/api/2023-10/graphql.json`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token": STOREFRONT_ACCESS_TOKEN,
        },
        method: "POST",
        body: body,
      }
    );

    return response.json();
  } catch (error) {
    // handle errors
    console.log(error);
  }
}

export function isDefaultOption(option) {
  return (
    option.name === "Title" &&
    (Array.isArray(option.values)
      ? option.values.every(value => value === "Default Title")
      : option.value === "Default Title")
  );
}

export function isNotDefaultOption(option) {
  return !isDefaultOption(option);
}

export function decodeStorefrontId(id) {
  if (id) {
    let guid = id;
    try {
      guid = window.atob(id);
    } catch (error) {
      // ignore
    }
    const decodedId = guid.split("/").pop();
    return !isNaN(Number(decodedId)) ? Number(decodedId) : decodedId;
  }
  return id;
}
